// custom typefaces
//import "typeface-montserrat"
//import "typeface-merriweather"

// syntax highlighter
//import "prismjs/themes/prism.css"

import "./src/utils/bootstrap.min.css"

import "./src/styles/global.css"

import 'jquery/dist/jquery.min.js'
import 'popper.js/dist/popper.min'
import 'bootstrap/dist/js/bootstrap.min.js'